
export const siteLinks =
    {
        home: "/",
        about: "/about",
        pricing: "/pricing",
        services: "/services",
        contact: "/contact",
        hosting: "/hosting",
        consulting: "/consulting",
        maintenance: "/maintenance",
        webdevelopment: "/webdevelopment",
        design: "/design",
        uiux: "/ui-ux",
        responsive: "/responsive",
        mobileapps: "/mobileapps",
        react: "/react",
        python: "/python",
        spring: "/spring",
        webhosting: "/webhosting",
        applicationhosting: "/application-hosting",
        clouddatabases: "/cloud-databases",
        hybridcloud: "/hybrid-cloud",
        filestorage: "/filestorage",
        backups: "/backups"
    };

export const getServices = (t) => [
    {
        title: t('Web Development'),
        description: t('Wij bouwen snelle, moderne websites die niet alleen goed presteren maar ook perfect aansluiten bij de behoeften van jouw bedrijf.'),
        icon: 'bx bx-laptop',
    },
    {
        title: t('App Development'),
        description: t('Van webapplicaties tot mobiele apps: wij ontwerpen en ontwikkelen op maat gemaakte oplossingen die jouw bedrijfsprocessen verbeteren.'),
        icon: 'bx bxs-badge-check',
    },
    {
        title: t('Hosting'),
        description: t('Wij bieden betrouwbare hostingoplossingen met continue monitoring, zodat jouw website of applicatie altijd beschikbaar is.'),
        icon: 'bx bx-cloud',
    },
    {
        title: t('Onderhoud'),
        description: t('Wij zorgen ervoor dat jouw technologie probleemloos functioneert, zodat jij je volledig kunt focussen op je onderneming.'),
        icon: 'bx bx-wrench',
    },
];


export const devFeatures = [
    {href: "/contact", icon: "bx bxs-badge-check", label: "Web Development"},
    {href: "/contact", icon: "bx bxs-badge-check", label: "Design"},
    {href: "/contact", icon: "bx bxs-badge-check", label: "UI / UX"},
    {href: "/contact", icon: "bx bxs-badge-check", label: "Responsive Design"},
    {href: "/contact", icon: "bx bxs-badge-check", label: "Mobile Apps"},
    {href: "/contact", icon: "bx bxs-badge-check", label: "React Development"},
    {href: "/contact", icon: "bx bxs-badge-check", label: "Python Development"},
    {href: "/contact", icon: "bx bxs-badge-check", label: "Spring Development"},
];


export const hostingFeatures = [
    {href: "/contact", icon: "bx bxs-badge-check", label: "Web Hosting"},
    {href: "/contact", icon: "bx bxs-badge-check", label: "Application Hosting"},
    {href: "/contact", icon: "bx bxs-badge-check", label: "Cloud Databases"},
    {href: "/contact", icon: "bx bxs-badge-check", label: "Hybrid Cloud"},
    {href: "/contact", icon: "bx bxs-badge-check", label: "File Storage"},
    {href: "/contact", icon: "bx bxs-badge-check", label: "Backup Systems"},
];
