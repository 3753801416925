import {AnimatedButton} from "./AnimatedButton";
import {t} from "i18next";

export function Contact() {
    return (
        <div className={"flex justify-center items-center h-full mt-20"}>
            <div className={"border border-gray-200 border-1 flex-shrink-0"}>
                <div className={"flex flex-col xl:flex-row justify-between items-center h-full p-5 xl:gap-24 mb-4"}>
                    <div className={"text-center xl:text-left"}>
                        <p className={"font-bold text-xl mt-5 whitespace-nowrap"}>{t("Heb je nog vragen of informatie nodig?")}</p>
                        <p className={"text-gray-500 mt-1 whitespace-nowrap"}>{t("Aarzel dan niet om ons te contacteren.")}</p>
                    </div>
                    <AnimatedButton
                        spanMargin={"ml-2"}
                        className={"mt-5 flex-shrink-0 logo-purple"}
                        animation={"fade"}
                        icon={"bxs-pencil"}
                        style={{width: '150px', height: '50px'}}
                    >
                        {t("Contacteer ons")}
                    </AnimatedButton>
                </div>
            </div>
        </div>
    );
}
