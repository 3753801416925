import React from "react";
import {t} from "i18next";

export function ColoredBanner(props) {
    const {children} = props

    return (
        <>
            <div className="relative">
                {/* Background Image */}
                <img
                    src="/images/bg_lines.svg"
                    alt="background"
                    className="absolute inset-0 w-full h-full object-cover opacity-40 z-10"
                />
                {/* Page Title Area */}
                <div className="relative">
                    <div className="page-title-area">
                        <div className="text-center z-10">
                            <div className="page-title-content">
                                <h1 className="text-white text-5xl">{t(children)}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
