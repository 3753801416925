import {t} from "i18next";
import {DropdownHover} from "../DropdownHover";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {siteLinks} from "../../data/SiteInfo";

/*<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
</svg>*/

export function Navbar(props) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <nav className="p-5 bg-white">
            <div className="container mx-auto flex items-center justify-between">
                {/* Logo */}
                <div>
                    <img src="/images/vertry-h.svg" alt="logo" width={125} height={125}/>
                </div>

                {/* Burger menu for smaller screens */}
                <div className="md:hidden">
                    <button
                        onClick={toggleMenu}
                        className="text-gray-800 focus:outline-none focus:ring"
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16m-7 6h7"
                            ></path>
                        </svg>
                    </button>
                </div>

                {/* Nav for larger screens */}
                <div className="hidden md:flex space-x-8 nav-link">
                    <Link to={siteLinks.home} className="hover:text-blue-800">
                        {t("Home")}
                    </Link>
                    <Link to={t(siteLinks.about)} className="hover:text-blue-800">
                        {t("Over ons")}
                    </Link>
                    <Link to={t(siteLinks.pricing)} className="hover:text-blue-800">
                        {t("Prijzen")}
                    </Link>
                    <DropdownHover title={t("Services")}></DropdownHover>
                    <Link to={t(siteLinks.contact)} className="hover:text-blue-800">
                        {t("Contact")}
                    </Link>
                </div>

                {/* Button */}
                <div className="hidden md:flex space-x-4">
                    <button className={"logo-purple button-size logo-gradient-hover text-white font-medium px-6 py-3 rounded-lg shadow-lg cursor-pointer"} aosDelay={"0"}>{t(" Probleem melden")}</button>
                </div>
            </div>

            {/* Dropdown for smaller screens */}
            {isMenuOpen && (
                <div className="md:hidden bg-white shadow-md mt-2">
                    <div className="flex flex-col space-y-2 p-4">
                        <Link to={t(siteLinks.home)} className="hover:text-blue-800">
                            {t("Home")}
                        </Link>
                        <Link to={t(siteLinks.about)} className="hover:text-blue-800">
                            {t("Over ons")}
                        </Link>
                        <Link to={t(siteLinks.pricing)} className="hover:text-blue-800">
                            {t("Prijzen")}
                        </Link>
                        <DropdownHover title={t("Services")}></DropdownHover>
                        <Link to={t(siteLinks.contact)} className="hover:text-blue-800">
                            {t("Contact")}
                        </Link>
                    </div>
                </div>
            )}
        </nav>
    );
}
