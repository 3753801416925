export function Experts(props) {
    const {experts} = props;

    return (
        // <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 items-stretch">
        <div>
            {experts.map((expert, index) => (
                <ExpertCard name={expert.name} jobTitle={expert.jobTitle} img={expert.imgSrc}/>
            ))}
        </div>
    )
}

function ExpertCard(props) {
    const {name, jobTitle, img} = props;
    return (
        <div>
            <div className="flex flex-col justify-center items-center">
                <div
                    className="border-4 border-blue-200 text-center text-gray-500 bg-white shadow-lg rounded-2xl"
                    data-aos-once="false"
                    data-aos-delay="800"
                >
                    <img src={img} className="md:w-72 md:h-72 p-3 rounded-3xl" alt="Expert"/>
                </div>
                <div className="p-6 flex flex-col items-center">
                    <p className={"font-bold text-xl"}>
                        {name}
                    </p>
                    <p className={"text-lg"}>
                        {jobTitle}
                    </p>
                </div>
            </div>
        </div>
    );
}

