import React, {useState} from "react";
import {Navbar} from "../components/Navigation/Navbar";
import {Footer} from "../components/Footer";
import {Helmet} from "react-helmet";
import {ColoredBanner} from "../components/ColoredBanner";
import {Link} from "react-router-dom";
import {AnimatedButton} from "../components/AnimatedButton";

export function Contact() {
    const pageTitle = "Vertry - Contact";
    const pageDescription = "Neem contact op met Vertry voor betrouwbare en innovatieve IT-oplossingen op maat. We helpen je graag verder.";
    const [formInfo, setFormInfo] = useState({
        name: "",
        email: "",
        number: "",
        subject: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = () => {
        console.log("Form submitted");
        console.log(formInfo);
        // send email with formInfo


        // clear form

        setFormInfo({
            name: "",
            email: "",
            number: "",
            subject: "",
            message: "",
        });
    };

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta property="og:description" content={pageDescription} />
                <meta property="og:url" content="https://www.vertry.com/contact" />
            </Helmet>
            <Navbar />
            <ColoredBanner>Contact</ColoredBanner>
            <div className="container mx-auto xl:py-10 shadow-md bg-white xl:p-10 lg:mt-20 mb-20">
                <div className="flex flex-wrap ">
                    <div className="w-full lg:w-1/2 p-4 lg:border-r lg:border-gray-200 h-96 mb-5 xl:mb-20">
                        <div className="contact-features-list  p-6 ">
                            <h3 className="text-xl font-semibold mb-4 ">Waarom Vertry?</h3>
                            <p className="mb-4 ">Bij Vertry geloven we in persoonlijke aandacht en innovatieve oplossingen. Of het nu gaat om webontwikkeling, hosting of onderhoud, wij staan voor je klaar.</p>
                            <ul className="list-disc list-none">
                                <li className="mb-2"><i className="bx bxs-badge-check text-green-400"></i><span className={"ms-1"}> Betrouwbare en op maat gemaakte IT-oplossingen</span></li>
                                <li className="mb-2"><i className="bx bxs-badge-check text-green-400"></i><span className={"ms-1"}> Persoonlijk contact en directe ondersteuning</span></li>
                                <li className="mb-2"><i className="bx bxs-badge-check text-green-400"></i><span className={"ms-1"}> Altijd up-to-date met de nieuwste technologie</span></li>
                                <li className="mb-2"><i className="bx bxs-badge-check text-green-400"></i><span className={"ms-1"}> Flexibele samenwerkingen, afgestemd op jouw behoeften</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 p-4">
                        <div className="contact-form  p-6 ">
                            <h3 className="text-xl font-semibold mb-4">Neem contact op</h3>
                            <form>
                                <div className="flex flex-wrap -mx-2">
                                    <div className="w-full md:w-1/2 px-2 mb-4">
                                        <input type="text" name="name" placeholder="Naam" value={formInfo.name} onChange={handleChange} className="form-control w-full p-2 border border-gray-300 rounded" required />
                                    </div>
                                    <div className="w-full md:w-1/2 px-2 mb-4">
                                        <input type="text" name="email" placeholder="Email" value={formInfo.email} onChange={handleChange} className="form-control w-full p-2 border border-gray-300 rounded" required />
                                    </div>
                                    <div className="w-full md:w-1/2 px-2 mb-4">
                                        <input type="text" name="number" placeholder="Telefoon nummer" value={formInfo.number} onChange={handleChange} className="form-control w-full p-2 border border-gray-300 rounded" required />
                                    </div>
                                    <div className="w-full md:w-1/2 px-2 mb-4">
                                        <input type="text" name="subject" placeholder="Onderwerp" value={formInfo.subject} onChange={handleChange} className="form-control w-full p-2 border border-gray-300 rounded" required />
                                    </div>
                                    <div className="w-full px-2 mb-4">
                                        <textarea name="message" cols="30" rows="6" placeholder="Bericht" value={formInfo.message} onChange={handleChange} className="form-control w-full p-2 border border-gray-300 rounded" required></textarea>
                                    </div>
                                    <div className="w-full text-center">
                                        <button type="submit" onClick={handleSubmit}>
                                            <AnimatedButton className={"logo-purple"}><i className="bx bxs-paper-plane"></i> Verstuur Bericht<span></span></AnimatedButton>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="contact-info mt-10 p-6">
                    <div className="contact-info-content text-center">
                        <h3 className="text-xl font-semibold mb-4">Meteen contact opnemen</h3>
                        <h2 className="text-2xl mb-4">
                            <span className="number">+471717785</span>
                            <span className="or mx-2">of</span>
                            <span className="email">info@vertry.be</span>
                        </h2>
                        <ul className="social flex justify-center space-x-4">
                            <li><Link to={"https://www.facebook.com/profile.php?id=61572001694726"}><i className="bx bxl-facebook"></i></Link></li>
                            <li><Link to={"https://www.linkedin.com/company/vertry"}><i className="bx bxl-linkedin"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
