import React from "react";
import { t } from "i18next";
import { Helmet } from "react-helmet";

export function NotFound() {
    const pageTitle = "404 - Page not found";
    const pageDescription = "De pagina die u zoekt, is mogelijk verwijderd, heeft een andere naam gekregen of is tijdelijk niet beschikbaar.";

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta property="og:description" content={pageDescription} />
                <meta property="og:url" content="https://www.vertry.com/404" />
                <meta property="og:image" content="https://www.vertry.com/images/404.png" />
                <meta name="robots" content="noindex" />
            </Helmet>

            <div className="flex items-center justify-center h-screen bg-gray-100">
                <div className="text-center">
                    <img
                        src="/images/404.png"
                        alt={t("error")}
                        width={800}
                        className="mx-auto h-auto mb-6"
                    />
                    <h3 className="text-5xl font-semibold mb-6">{t("Pagina niet gevonden")}</h3>
                    <p className="text-gray-600">
                        {t("De pagina die u zoekt, is mogelijk verwijderd,")}
                    </p>
                    <p className="text-gray-600 mb-6">
                        {t("heeft een andere naam gekregen of is tijdelijk niet beschikbaar.")}
                    </p>
                    <a
                        className="inline-block px-6 py-3 text-white bg-blue-600 rounded hover:bg-blue-700 transition"
                        href="/"
                    >
                        <i className="bx bx-rotate-left mr-2"></i>
                        {t("Terug")}
                    </a>
                </div>
            </div>
        </>
    );
}
