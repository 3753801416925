export function BannerArea(props) {
    const {children} = props
    return (
        <>
            <div className="p-20 service-bg">
                <div className="lg:container mx-auto">
                    {children}
                </div>
            </div>
        </>
    )
}
