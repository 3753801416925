import {Badge} from "./Badge";

export function FeatureList({ features }) {
    return (
        <div className="grid grid-cols-2 gap-x-5 xl:gap-x-2 mt-10 lg:-ms-2">
            {features.map((feature, index) => (
                <Badge key={index} {...feature} />
            ))}
        </div>
    );
}
