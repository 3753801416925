import ChatBot from "react-chatbotify";
import {t} from "i18next";

export const VertryChatBot = () => {
    const faqOptions = [
        "Welke diensten biedt Vertry aan?",
        "Wat kosten jullie diensten?",
        "Kan ik een op maat gemaakte oplossing krijgen?",
        "Bieden jullie hostingdiensten aan?",
        "Hoe kan ik beginnen?",
        "Contact opnemen"
    ];

    const contactFormLink = "/contact"; // Vervang dit door de daadwerkelijke link naar het contactformulier

    const flow = {
        start: {
            message: "Hallo! 👋 Ik ben de Vertry Assistent en ik ben hier om je te helpen. Of je nu vragen hebt over onze diensten, prijzen, of iets anders.",
            transition: {duration: 1000},
            path: "show_options"
        },
        show_options: {
            message: "Hier zijn enkele veelgestelde vragen waarmee ik je kan helpen:",
            options: faqOptions,
            path: "process_faqs"
        },
        unknown_input: {
            message: "Sorry, ik begrijp je bericht niet helemaal.",
            transition: {duration: 500},
            path: "ask_redirect"
        },
        ask_redirect: {
            message: "Wil je doorgestuurd worden naar het contactformulier?",
            options: ["Ja", "Nee"],
            path: "process_contact_redirect"
        },

        process_contact_redirect: {
            transition: {duration: 0},
            chatDisabled: true,
            path: async (params) => {
                if (params.userInput === "Ja") {
                    // Redirect user to contact form
                    window.location.href = contactFormLink;
                }
            }
        },

        process_faqs: {
            transition: {duration: 0},
            chatDisabled: true,
            path: async (params) => {
                let responseMessage = "";
                switch (params.userInput) {
                    case "Welke diensten biedt Vertry aan?":
                        responseMessage = "Bij Vertry specialiseren we ons in:\n- Op maat gemaakte websites (van eenvoudige one-pagers tot uitgebreide platforms)\n- Maatwerk webapplicaties\n- Hostingdiensten met onderhoudscontracten\n- Geautomatiseerde platformen om jouw online aanwezigheid te versterken.\n\nNeem contact met ons via het contactformulier";
                        break;
                    case "Wat kosten jullie diensten?":
                        responseMessage = "Onze prijzen hangen af van de complexiteit en omvang van je project. We bieden betaalbare tarieven die zijn afgestemd op kleine bedrijven. Voor een gedetailleerde offerte, neem contact met ons op het contactformulier";
                        break;
                    case "Kan ik een op maat gemaakte oplossing krijgen?":
                        responseMessage = "Zeker! We zijn gespecialiseerd in het creëren van oplossingen die perfect aansluiten op jouw specifieke behoeften. Laten we bespreken hoe we jouw idee kunnen realiseren. Neem contact met ons op het contactformulier";
                        break;
                    case "Bieden jullie hostingdiensten aan?":
                        responseMessage = "Ja, we bieden betrouwbare hostingdiensten aan, compleet met onderhoudscontracten die beveiligingsupdates, regelmatige back-ups en meer omvatten. Neem contact met ons op het contactformulier";
                        break;
                    case "Hoe kan ik beginnen?":
                        responseMessage = "Beginnen met Vertry is eenvoudig! Vul ons contactformulier in en we begeleiden je door de volgende stappen.";
                        break;
                    case "Contact opnemen":
                        window.location.href = contactFormLink;
                        break;
                    default:
                        return "unknown_input";
                }
                await params.injectMessage(responseMessage);
                return "prompt_again";
            },
        },
        prompt_again: {
            message: "Heb je nog andere vragen of heb je verdere hulp nodig? Zo niet, neem dan direct contact met ons op via het contactformulier.",
            options: faqOptions,
            path: "process_faqs"
        },
    };

    return (
        <div style={{fontSize: "10px"}}>
            <ChatBot settings={{
                general: {embedded: false, showFooter: false, primaryColor: "#EE5E63FF", secondaryColor: "#262163FF"},
                header: {
                    title: <div style={{
                        cursor: 'pointer',
                        margin: '0px',
                        fontSize: '20px',
                        fontWeight: 'bold'
                    }}>{t('Vertry Chat')}</div>
                },
                notification: {disabled: false, volume: 0.0},
                tooltip: {text: <div style={{fontSize: '16px'}}>{t('Vragen? Stel ze hier!')}</div>},
                chatButton: {icon: "/images/vertry-v-white.svg"},
                chatHistory: {storageKey: "vertry_faq_bot"},
                botBubble: {simStream: true},
            }} flow={flow}/>
        </div>
    );
};
