import React, { useState } from "react";
import { Link } from "react-router-dom";

export function DropdownHover(props) {
    const {title, className } = props;
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`relative inline-block text-left cursor-pointer z-50 hover:text-blue-800 ${className}`}>
            {/* Dropdown Button */}
            <div
                onClick={toggleDropdown}
                className="inline-flex items-center justify-center w-fullbg-white focus:outline-none">
                {title}
                <svg
                    className="ml-2 -mr-1 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </div>

            {/* Dropdown Menu */}
            {isOpen && (
                <div
                    className="origin-top-right absolute right-0 mt-2 w-56
                    rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5
                    focus:outline-none"
                    role="menu"
                >
                    <div className="py-1" role="none">
                        <Link
                            to="/development"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                        >
                            Ontwikkeling
                        </Link>
                        <Link
                            to="/hosting"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                        >
                            Hosting
                        </Link>
                        <Link
                            to="/maintenance"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                        >
                            Onderhoud
                        </Link>
                        <Link
                            to="/consultancy"
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                        >
                            Consultancy
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
}
