import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationNL from './locales/nl/translation.json';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: translationEN },
        nl: { translation: translationNL },
    },
    lng: 'nl', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: { escapeValue: false }, // React already escapes
});

export default i18n;
