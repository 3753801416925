import {Description} from "./Description";
import {FunFactSquare} from "./FunFactSquare";
import React from "react";
import {Contact} from "./Contact";
import {Title} from "./Title";
import {t} from "i18next";

export function WhyChooseUs() {
    return (
        <>
            <div>
                <div className={"flex justify-center"}>
                    <Title>{t("Waarom kiezen voor ons?")}</Title>
                </div>
                <div className={"container flex mx-auto"}>
                    <Description
                        className={"mt-10 text-center lg:text-left lg:mt-0 lg:p-10"}>{t("Bij ons draait alles om het versterken van jouw online aanwezigheid.\n" +
                        "                        Of je nu een startende ondernemer bent of een gevestigde professional, wij bieden op maat\n" +
                        "                        gemaakte oplossingen die perfect aansluiten bij jouw behoeften. Met een persoonlijke aanpak,\n" +
                        "                        betaalbare tarieven en een passie voor kwaliteit, zorgen wij ervoor dat jouw website of\n" +
                        "                        applicatie niet alleen goed functioneert, maar ook indruk maakt. Kies voor ons en ervaar de\n" +
                        "                        combinatie van creativiteit, technische expertise en betrouwbare service. Samen bouwen we jouw\n" +
                        "                        digitale succes.")}
                    </Description>
                </div>
                <div className={"relative flex flex-row flex-wrap justify-center mt-10 lg:mt-0 lg:p-10 gap-12 lg:gap-32"}>
                    <FunFactSquare animationDelay={"100"} bxIcon={"bx-check-square"} numberToDisplay={0}>Afgewerkte
                        Projecten</FunFactSquare>
                    <FunFactSquare animationDelay={"200"} bxIcon={"bx-star"} numberToDisplay={0}>Tevreden
                        Klanten</FunFactSquare>
                    <FunFactSquare animationDelay={"300"} bxIcon={"bx-run"} numberToDisplay={0}>Lopende
                        Projecten</FunFactSquare>
                    <FunFactSquare animationDelay={"400"} bxIcon={"bx-award"} numberToDisplay={0}>Positieve
                        Reviews</FunFactSquare>
                    <img className={"absolute bottom-0 lg:top-0"} src={"/images/map1.png"} alt={"transparent dots"}/>
                </div>
                <Contact/>
            </div>
        </>
    )
}
