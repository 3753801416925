import {ServiceDescription} from "./ServiceDescription";
import {Image} from "./Image";

export function DevSection(props) {
    const {devFeatures} = props

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 mt-8 p-10 md:p-20 mx-auto overflow-hidden">
            <div className={"flex items-center justify-center"}>
                <div className="xl:w-3/4">
                    <ServiceDescription
                        className={""}
                        iconSrc="/images/icons-01-sm.svg"
                        title="Web, Apps & Mobile Development"
                        description="Wij bieden op maat gemaakte web- en mobiele applicaties die zijn ontworpen om jouw bedrijfsprocessen te optimaliseren. Met een focus op gebruiksvriendelijkheid, snelheid en schaalbaarheid zorgen wij ervoor dat jouw digitale oplossingen klaar zijn voor de toekomst."
                        features={devFeatures}
                    />
                </div>
            </div>
            <div className={"flex items-center justify-center"}>

                <Image className="lg:mt-36 xl:mt-0"
                       imgSrc="/images/service1.png"
                       fadeDirection={"left"}/>
            </div>
        </div>
    );
}
