import {FeatureList} from "./FeatureList";

export function ServiceDescription(props) {
    const {title, description, features, className, iconSrc} = props;
    return (
        <div>
            <div className={className + " mt-16 lg:w-4/5"}>
                <div className="icon">
                    <img src={iconSrc} alt={title} width={40} height={40} className={"-ms-1 mb-2"}></img>
                </div>
                <h2 className="text-4xl">{title}</h2>
                <p className="text-gray-500 mt-5">{description}</p>
                <div className="mb-10 lg:mb-0">
                    <FeatureList features={features}/>
                </div>
            </div>
        </div>
    );
}
