import {Link} from "react-router-dom";

export function Card(props) {
    const {title, description, link, children} = props

    return (
            <div className="max-w-lg bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                <div className="p-10 pb-0">
                    <Link to={link}>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
                    </Link>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{description}</p>
                </div>
                {children}
            </div>
    );
}
