import {Navbar} from "../components/Navigation/Navbar";
import {Card} from "../components/Card";
import {Footer} from "../components/Footer";
import React from "react";
import {Helmet} from "react-helmet";
import {t} from "i18next";
import {ColoredBanner} from "../components/ColoredBanner";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import 'react-tabs/style/react-tabs.css';

export function Pricing() {
    const servicePrices = [
        {
            title: "Website",
            description: "Een professionele one-pager website met contactformulier. Ideaal voor starters en kleine ondernemingen die hun online aanwezigheid willen versterken.",
            price: 499,
            features: [
                "Uniek, modern en responsive design",
                "Alle bedrijfsinformatie overzichtelijk weergegeven",
                "Contactformulier voor directe klantcommunicatie",
                "Basis SEO-configuratie voor betere vindbaarheid",
                "Snelle laadtijden en geoptimaliseerde afbeeldingen",
                "Eigen inbreng en aanpassingen mogelijk",
            ],
        },
        {
            title: "Webshop",
            description: "Een op maat gemaakte webshop met alle benodigde functionaliteiten om online te verkopen. Geschikt voor zowel kleine als groeiende ondernemingen.",
            price: 1500,
            features: [
                "Uniek, modern en responsive design",
                "Productcatalogus met onbeperkt aantal producten",
                "Automatische facturatie en betalingen",
                "API-integratie",
                "Zoekfunctionaliteit",
                "Winkelwagen- en afrekenproces op maat",
                "Basis SEO",
                "Beveiligde checkout (SSL)",
                "Klantenbeoordelingen en review-secties",
                "Eigen inbreng en aanpassingen mogelijk",
            ],
        },
        {
            title: "Webapplicatie",
            description: "Een complexe, volledig op maat gemaakte webapplicatie met geavanceerde functionaliteiten, integraties en schaalbaarheid voor op lange termijn.",
            price: 2250,
            features: [
                "Uniek, modern en responsive design",
                "Uniek, schaalbaar en op maat gemaakt platform",
                "Volledige API-integratie met externe diensten",
                "Login & Gebruikersbeheer",
                "Databaseintegratie en performante data-opslag",
                "Responsive design voor desktop, tablet en mobiel",
                "Analytics en rapportagemogelijkheden",
                "Eigen inbreng en aanpassingen mogelijk",
            ],
        },
    ];

    const maintenancePrices = [
        {
            title: "Onderhoud Basis",
            description: "Regelmatig onderhoud en updates voor je website of webshop.",
            price: 19.99,
            features: [
                "Maandelijkse updates",
                "Back-ups",
                "Beveiligingsupdates",
                "Kleine aanpassingen",
            ],
        },
        {
            title: "Onderhoud Plus",
            description: "Uitgebreid onderhoud en ondersteuning voor je website of webshop.",
            price: 29.99,
            features: [
                "Wekelijkse updates",
                "Back-ups",
                "Beveiligingsupdates",
                "Kleine en middelgrote aanpassingen",
                "Prioritaire ondersteuning",
            ],
        },
    ];

    const hostingPrices = [
        {
            title: "Webhosting",
            description: "Betrouwbare en snelle webhosting voor je website of webshop.",
            price: 19.99,
            features: [
                "Dagelijkse back-ups",
                "SSL-certificaat",
                "Onbeperkt dataverkeer",
                "Onbeperkte opslagruimte",
                "1Gbs bandwidth",
                "99,9% uptime garantie",
                "24/7 support",
            ],
        },
        {
            title: "Back-end service",
            description: "Krachtige backendservices voor je webapplicatie.",
            price: 29.99,
            features: [
                "Dagelijkse back-ups",
                "SSL-certificaat",
                "Onbeperkt dataverkeer",
                "Onbeperkte opslagruimte",
                "1Gbs bandwidth",
                "99,9% uptime garantie",
                "24/7 support",
            ],
        },
    ];

    const pageTitle = t("Vertry - Pricing");
    const pageDescription = t("Ontdek onze competitieve prijzen voor websites, webshops en webapplicaties. Vertry biedt op maat gemaakte IT-oplossingen tegen betaalbare tarieven.");

    return (
        <div className="relative bg-[#f4f6fc]">
            <Helmet>
                <title>{pageTitle}</title>
                <meta property="og:description" content={pageDescription}/>
                <meta property="og:url" content="https://www.vertry.com/pricing"/>
            </Helmet>
            <Navbar/>
            <ColoredBanner>Onze prijzen</ColoredBanner>
            <div className={"flex flex-col items-center p-20"}>
                <Tabs className="custom-tabs">
                    <TabList className="custom-tab-list flex justify-center mb-20">
                        <Tab className="custom-tab">
                            <div
                                className="pricing-button-hover rounded-s-full bg-pink overflow-hidden shadow-lg h-12 w-40 flex items-center justify-center text-white cursor-pointer">
                                <span>Ontwikkeling</span>
                            </div>
                        </Tab>
                        <Tab className="custom-tab">
                            <div
                                className="pricing-button-hover rounded-e-full bg-vertry-purple overflow-hidden shadow-lg h-12 w-40 flex items-center justify-center text-white cursor-pointer">
                                <span>Onderhoud</span>
                            </div>
                        </Tab>
                    </TabList>

                    <TabPanel>
                        <div className="flex flex-wrap justify-center gap-6">
                            {servicePrices.map((price, index) => (
                                <Card key={index} title={t(price.title)} description={t(price.description)}>
                                    <div className={"border border-dotted mt-10"}></div>
                                    <div className="p-10 pt-6">
                                        <h5 className="mb-6 text-3xl font-bold text-gray-900">€{price.price}<span
                                            className="text-sm ml-2">{t("vanaf")}</span></h5>
                                        <ul className="text-gray-700">
                                            {price.features.map((feature, index) => (
                                                <li key={index} className={"pt-2 text-md"}><span
                                                    className={"bx bx-check-circle text-green-500 me-2"}/>{t(feature)}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="flex flex-wrap justify-center gap-6">
                            {maintenancePrices.map((price, index) => (
                                <Card key={index} title={t(price.title)} description={t(price.description)}>
                                    <div className={"border border-dotted mt-10"}></div>
                                    <div className="p-10 pt-6">
                                        <h5 className="mb-6 text-3xl font-bold text-gray-900">€{price.price}<span
                                            className="text-sm ml-2">{t("/ maand")}</span></h5>
                                        <ul className="text-gray-700">
                                            {price.features.map((feature, index) => (
                                                <li key={index} className={"pt-2 text-md"}><span
                                                    className={"bx bx-check-circle text-green-500 me-2"}/>{t(feature)}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
            <div className="custom-tab-list flex justify-center mb-20">
                <div
                    className="pricing-button-hover rounded-full bg-vertry-purple overflow-hidden shadow-lg h-12 w-40 flex items-center justify-center text-white cursor-pointer">
                    Hosting
                </div>
            </div>
            <div className="flex flex-wrap justify-center pb-20 gap-6">
                {hostingPrices.map((price, index) => (
                    <Card key={index} title={t(price.title)} description={t(price.description)}>
                        <div className={"border border-dotted mt-10"}></div>
                        <div className="p-10 pt-6">
                            <h5 className="mb-6 text-3xl font-bold text-gray-900">€{price.price}<span
                                className="text-sm ml-2">{t("vanaf / maand")}</span></h5>
                            <ul className="text-gray-700">
                                {price.features.map((feature, index) => (
                                    <li key={index} className={"pt-2 text-md"}><span
                                        className={"bx bx-check-circle text-green-500 me-2"}/>{t(feature)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Card>
                ))}
            </div>
            <Footer/>
        </div>
    );
}
