import React from "react";
import { Navbar } from "../components/Navigation/Navbar";
import { Footer } from "../components/Footer";
import { Description } from "../components/Description";
import { ColoredBanner } from "../components/ColoredBanner";
import { WhyChooseUs } from "../components/WhyChooseUs";
import { Experts } from "../components/Experts";
import { Title } from "../components/Title";
import { AnimatedButton } from "../components/AnimatedButton";
import { Helmet } from "react-helmet";

export function About() {
    const experts = [
        {
            name: "Dean Vervaeck",
            jobTitle: "Oprichter / Ontwikkelaar",
            imgSrc: "/images/experts/expert1.png",
        },
    ];

    const pageTitle = "Vertry - About";
    const pageDescription = "Leer meer over Vertry en hoe ons idee is ontstaan. Ontdek ons verhaal, onze missie, en de mensen achter onze innovatieve IT-oplossingen.";

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content="https://hepro-react.envytheme.com/images/about-img.jpg" />
                <meta property="og:url" content="https://www.vertry.com/about" />
            </Helmet>
            <Navbar />
            <ColoredBanner>Over ons</ColoredBanner>
            <div className="md:mx-52 mb-20 p-10 lg:p-0 lg:mt-20">
                <div className="flex flex-col lg:flex-row center items-center py-10 px-6 lg:px-20">
                    <div className="lg:w-1/2 text-center lg:text-left">
                        <Title className={"text-4xl font-bold"}>Hoe is het idee ontstaan?</Title>
                        <Description className={"mt-10 lg:mt-2"}>
                            Ons verhaal begon met een gedeelde passie voor innovatie en
                            klantgerichte oplossingen. Wat startte als een klein idee, groeide al snel uit tot een
                            missie om bedrijven en individuen te ondersteunen in hun digitale transformatie. Met hard
                            werk, toewijding en een focus op kwaliteit, zijn we een platform aan het opbouwen waarmee we
                            oplossingen bieden die écht het verschil kunnen maken.
                        </Description>
                    </div>

                    <div
                        className="lg:w-1/2 mt-10 lg:mt-0 flex justify-center relative"
                        data-aos="fade-up"
                        data-aos-delay="400"
                        data-aos-duration="1200"
                    >
                        <img
                            src="https://hepro-react.envytheme.com/images/about-img.jpg"
                            alt="Afbeelding die ons verhaal illustreert"
                            className="w-3/4 lg:w-2/3 shadow-lg"
                        />
                    </div>
                </div>
            </div>
            <div className={"bg-[#f4f6fc]"}>
                <div className={"flex justify-center items-center flex-col p-20"}>
                    <Title className={"mb-10"}>Wie zijn wij?</Title>
                    <Experts experts={experts} />
                </div>
            </div>
            <div className={"p-20 md:p-10"}>
                <WhyChooseUs />
            </div>
            <div>
                <div className={"bg-[#f4f6fc]"}>
                    <div className={"relative flex justify-center items-center content-center p-20 h-96"}>
                        <div className="shape10 animate-spin"><img
                            src="https://hepro-react.envytheme.com/images/shape/shape10.png"
                            alt="draaivorm"
                        /></div>
                        <div className="shape11 animate-bounce-c"><img
                            src="https://hepro-react.envytheme.com/images/shape/shape7.png"
                            alt="bewegende vorm"
                        /></div>
                        <div className={"flex flex-col items-center lg:gap-y-4 xl:gap-y-12"}>
                            <Title className={"text-center lg:w-1/2 xl:w-full"}>
                                Wij proberen je altijd zo goed mogelijk bij te staan.
                            </Title>
                            <AnimatedButton
                                href="/contact"
                                spanMargin={"ml-2"}
                                icon={"bxs-hot"}
                                className={"logo-gradient logo-purple-hover hover:text-white mt-5 lg:mt-0"}
                            >
                                Contacteer ons
                            </AnimatedButton>
                        </div>
                        <div className="shape12 animate-spin"><img
                            src="https://hepro-react.envytheme.com/images/shape/shape11.png"
                            alt="ronddraaiende vorm"
                        /></div>
                        <div className="shape13 animate-spin"><img
                            src="https://hepro-react.envytheme.com/images/shape/shape12.png"
                            alt="andere ronddraaiende vorm"
                        /></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
