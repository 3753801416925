import {Navbar} from "../components/Navigation/Navbar";
import {Footer} from "../components/Footer";
import {Helmet} from "react-helmet";
import React from "react";

export function Services() {
    return (
        <Helmet>
            <title>Vertry - Services</title>
            <meta property="og:description" content="Bekijk onze diensten"/>
            <meta property="og:url" content="https://www.vertry.com/services"/>
            <Navbar/>
            <h1>Services</h1>
            {/*<ColoredBanner>Services</ColoredBanner>*/}
            <Footer/>
        </Helmet>
    );
}
