import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export function Footer() {
    const {t} = useTranslation();

    const socialMediaLinks = (
        {
            linkedIn: "https://www.linkedin.com/",
            facebook: "https://www.facebook.com/"
        }
    );

    const contactInfo = [
        { url: "mailto:info@vertry.be?subject=Contact", text: "info@vertry.be" },
        { text: "+324/71.71.77.85" },
        { text: "Merelnest 12, 2221 Booischot, België" },
        { text: `${t('BTW')}:` }
    ];

    return (
        <>
            <footer className="relative bg-gray-800 text-white py-10 bottom-0"
            >
                <div className="absolute inset-0">
                    <img
                        src="/images/footer-shape2.png"
                        alt="background"
                        className="w-full h-full object-cover opacity-50"
                    />
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                </div>
                <div className="relative container mx-auto px-4 "
                     data-aos="fade-right"
                     data-aos-once="false"
                     data-aos-delay="100"
                     data-aos-duration="1800">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <div className="space-y-4">
                            <div className="logo">
                                <Link to={"/"}><img src="/images/vertry-h-white.svg" alt={"company logo"} width={150}/></Link>
                            </div>
                            <p>Betrouwbare IT-oplossingen.</p>
                        </div>
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-gray-300">{t('Bedrijf')}</h3>
                            <ul className="space-y-2">
                                <li>
                                    <Link to="/about" className="hover:underline">{t('Over ons')}</Link>
                                </li>
                                <li>
                                    <Link to={"/services"} className="hover:underline">{t('Diensten')}</Link>
                                </li>
                                <li>
                                    <Link to={"/pricing"} className="hover:underline">{t('Prijzen')}</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold text-gray-300">Support</h3>
                            <ul className="space-y-2">
                                <li>
                                    <Link to={"/faq"} className="hover:underline">{t('Veel gestelde vragen')}</Link>
                                </li>
                                <li>
                                    <Link to={"/contact"} className="hover:underline">{t('Contacteer ons')}</Link>
                                </li>
                                <li><Link to={"/terms-conditions"}
                                          className="hover:underline">{t('Algemene voorwaarden')}</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-4">
                            <ul className="flex space-x-4">
                                <li><Link to={socialMediaLinks.facebook} target="_blank"
                                          className="hover:text-gray-400"><i
                                    className="bx bxl-facebook"></i></Link></li>
                                <li><Link to={socialMediaLinks.linkedIn} target="_blank"
                                          className="hover:text-gray-400"><i
                                    className="bx bxl-linkedin"></i></Link></li>
                            </ul>
                            <FooterLinks links={contactInfo} />
                        </div>
                    </div>
                    <div className="text-center mt-8">
                        <p>Copyright © 2024 Dean Vervaeck.</p>
                    </div>
                </div>
            </footer>
        </>
    );
}

function FooterLinks(props) {
    const {links} = props;
    return (
        <ul className="space-y-2">
            {links.map((link, index) => (
                <li key={index}>
                    {link.url ? (
                        <Link to={link.url} className="hover:underline">{link.text}</Link>
                    ) : (
                        <span>{link.text}</span>
                    )}
                </li>
            ))}
        </ul>
    );
}
