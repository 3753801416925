import './App.css';
import {Homepage} from "./pages/Homepage";
import AOS from "aos";
import "aos/dist/aos.css";
import {useEffect} from "react";
import {devFeatures, getServices, hostingFeatures} from "./data/SiteInfo";
import {useTranslation} from "react-i18next";
import {About} from "./pages/About";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Pricing} from "./pages/Pricing";
import {Contact} from "./pages/Contact";
import {NotFound} from "./pages/NotFound";
import {VertryChatBot} from "./components/Chatbot/VertryChatbot";
import PropTypes from "prop-types";
import {Services} from "./pages/Services";

function Switch(props) {
    return null;
}

Switch.propTypes = {children: PropTypes.node};

function App() {

    useEffect(() => {
        AOS.init();
    }, []);

    const {t} = useTranslation();
    const services = getServices(t);

    return (
        <>
{/*            <head>
                <link rel="stylesheet"
                      href="https://cdn.jsdelivr.net/npm/boxicons@latest/css/boxicons.min.css">
                </link>
            </head>*/}
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Homepage services={services}
                                                           devFeatures={devFeatures}
                                                           hostingFeatures={hostingFeatures}/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path={"/pricing"} element={<Pricing/>}/>
                        <Route path={"/contact"} element={<Contact/>}/>
                        <Route path={"/services"} element={<Services/>}/>
                        <Route path={"*"} element={<NotFound/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
            <VertryChatBot/>
        </>
    );
}

export default App;
