import {SiteLogo} from "./SiteLogo";

export function Intro({subtitle, description }) {
    return (
        <div
            className="text-4xl lg:text-5xl font-bold text-gray-800"
            data-aos="fade-up"
            data-aos-once="false"
            data-aos-delay="100"
            data-aos-duration="1200"
        >
            <SiteLogo/>
            <h1 className="mb-12 text-4xl md:text-5xl">{subtitle}</h1>
            <p className="mb-12 text-3xl md:text-4xl">{description}</p>
        </div>
    );
}
